.bg-white {
  background-color: @white;
}

.bg-primary {
  background-color: @primary-color;
}

.bg-primary-1 {
  background-color: @primary-1;
  //background-color: @primary-2;
  //background-color: @primary-3;
  //background-color: @primary-4;
  //background-color: @primary-5;
  //background-color: @primary-6;
  //background-color: @primary-7;
  //background-color: @primary-8;
}