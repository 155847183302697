.make-paddings(@size: 0, @increment: 4) when (@size <= 40) {
  .make-paddings(@size + @increment);

  @size-px: ~"@{size}px";

  .p-@{size} {
    padding: @size-px !important;
  }
  .pt-@{size} {
    padding-top: @size-px !important;
  }
  .pr-@{size} {
    padding-right: @size-px !important;
  }
  .pb-@{size} {
    padding-bottom: @size-px !important;
  }
  .pl-@{size} {
    padding-left: @size-px !important;
  }
}

.make-paddings();