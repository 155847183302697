.site-navigation {
  .logo-container {
    padding: 24px 16px 24px 24px;
  }

  .site-menu {
    .ant-menu-sub {
      .ant-menu-title-content {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  h3 {
    font-weight: 700;
    margin: 0 0 0 8px;
  }
}