.make-paddings(@size: 0, @increment: 4) when (@size <= 40) {
  .make-paddings(@size + @increment);

  @size-px: ~"@{size}px";

  .m-@{size} {
    margin: @size-px !important;
  }
  .mt-@{size} {
    margin-top: @size-px !important;
  }
  .mr-@{size} {
    margin-right: @size-px !important;
  }
  .mb-@{size} {
    margin-bottom: @size-px !important;
  }
  .ml-@{size} {
    margin-left: @size-px !important;
  }
}

.make-paddings();