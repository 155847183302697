
.@{form-item-prefix-cls} {
  &-with-help {
    margin-bottom: @form-item-margin-bottom;
  }

  &-with-help &-explain {
    margin-top: 0.25rem;
    font-size: 0.75rem;
  }
}