.site-header {
  border-radius: @border-radius-base;
  background: @white;

  .notification {
    margin-bottom: 0;

    span {
      color: @primary-4
    }
  }
}

.site-page-header {
  margin-bottom: 24px;
  background-color: @white;
  border-radius: @border-radius-base;
}